<template>
  <div>
    <div id="amapStation1" v-if="!showInit" style="z-index: 99999999;display: block;height: 100vh;"></div>
  
  <div class="gis-main gis-default" id="showmap">
    <div style="display: flex">
      <div class="layer-control">
        <layer-manage v-show="layerShow" ref="layerManage" class="layer" />
      </div>
      <div class="view">
        <div class="top-tool" style="width:100%">
          <LeftTop ref="LeftTop"></LeftTop>
          <searchTool ref="searchTool" />
        </div>
        <div ref="amap" class="map-size">
          
          <div id="amapStation">
            <layer-wms ref="layerWms" :layer-name="layerName" />
            <zoom ref="zoom" />
          </div>
        </div>
        <toolbar ref="Toolbar" :props-position="propsPosition" :bottom-position="bottomPosition" />
        <toolInfo ref="toolInfo" />
        <mark-info ref="markInfo"></mark-info>
        <editVector ref="editVector"></editVector>
        <edit-feature ref="editFeature"></edit-feature>
        <rightTool
          ref="rightTool"
          :legend-name="'overView'"
          :query="false"
          :layer-tree="true"
          :home-query="true"
          @closeDraw="closeDraw"
          @draw="draw"
          @settingsItem="settingsItem"
        />
        <bottom :display-list="displayList" :pipe-display-list="pipeDisplayList" />
      </div>
    </div>
    <dialog-display
      ref="dialogDisplay"
      :display-settings="displaySettings"
      @confirm="onConfirm"
    ></dialog-display>
    <query-info ref="queryInfo"></query-info>
    <station-info ref="stationInfo"></station-info>
    <station-desc ref="StationDesc"></station-desc>
    <div
      v-if="isEdit && drawTipmouse && drawType != 'rule'"
      ref="drawTipmouse"
      class="drawTipmouse"
    >
      {{ drawTipmouse }}
    </div>
    <div v-if="isEdit && drawType == 'rule'" ref="drawTipmouse" class="drawTipmouse">
      <span v-if="ruleM != 0">
        总长：<span style="color:red">{{ ruleM }}</span
        >米<br />{{ drawTipmouse }}
      </span>
      <span v-else>{{ tipMessege }}</span>
    </div>
  </div>
</div>
</template>

<script>
import '@/assets/gis/iconFont/iconfont.css'
import '@/assets/gis/iconFont/iconfont.js'
//import { loadMixin } from './load/amapLoad' //有地图的总入口都要混入
import { intMixin } from './load/amapInit'
import editVector from './tool/layer/editVector'
import zoom from './tool/question/zoom' //层级控制器
import layerWms from './tool/layer/layerWms' //瓦片
import bottom from './tool/layer/bottom'
import Toolbar from './tool/leftTool/Toolbar'
import rightTool from './tool/rightTool/index'
import searchTool from './tool/searchTool/index'
import toolInfo from './tool/layer/toolInfo'
import markInfo from './tool/layer/markInfo'
import editFeature from './tool/layer/editFeature'
import dialogDisplay from './dialog/display'
import layerManage from './layerManage/layerGis'
import topTool from './tool/topTool/index'
import SetBase from './apis/setBase'
import queryInfo from './tool/layer/queryInfo'
import stationInfo from './tool/layer/stationInfo'
import { commonLayer, initFilter, constantDict } from './utils/dict.js'
import { formatLocation } from './utils/geoTool.js'
import pl from '@/assets/gis/pl.png'
import './style/skin.scss'
import LeftTop from './tool/leftTool/leftTop.vue'
import StationDesc from './dialog/stationDesc.vue'
import {selectScadaLineWarn,selectScadaStationWarn} from './apis/base'

// import { log } from 'console'
export default {
  name: 'Index',
  components: {
    zoom,
    layerWms,
    bottom,
    Toolbar,
    rightTool,
    toolInfo,
    dialogDisplay,
    layerManage,
    topTool,
    markInfo,
    editFeature,
    queryInfo,
    stationInfo,
    editVector,
    searchTool,
    LeftTop,
    StationDesc
},
  mixins: [intMixin], //有地图的总入口都要混入
  data() {
    return {
      drawTipmouse: '',
      layerName: commonLayer['pressure'],
      propsPosition: 0.1, //组件边距
      bottomPosition: 40,
      searchPosition: 0.1,
      displaySettings: null,
      displayList: [],
      isEdit: false,
      isSelectNode: false,
      layerShow: true,
      pipeDisplayList: [],
      editTool: null,
      equipmentTool: null,
      dragState: true,
      loadStateSet: 0,
      toolIndex: 0,
      queryVector: true, //根据层级判断是否开启 默认关闭
      queryVectorGeo: [], //要吸附的数据
      queryVectorObj: null, //点位对象
      queryFeatrue: null, //吸附好的值
      queryLayer: '', //要查询的图层
      queryType: '',
      ruleObj: [],
      ruleM: '0',
      gis:false,
      LabelsLayerHighlightAll:[],
      timer:'',
      isClick:true,
      showInit:false,
    }
  },
  computed: {},
  watch: {
    loadStateSet(val) {
      console.log(this.loadStateSet)
      if (this.loadStateSet == 3) {
        this.setlayerInit()
      }
    },
    queryType(val) {
      this.setDrawPoint(val)
    }
  },
  destroyed(){
    if(this.timer){
      clearInterval(this.timer)
    }
  },
  mounted() {
    XE.ready().then(this.startup);
    //  this.layerName = commonLayer['pressure']
    this.setStorInit()
    this.findSetting()
  },
  methods: {
    startup() {
      var earth;
    var bgImagery;
            // earth = new XE.Earth('earthContainer');
            earth = new XE.Earth('amapStation1', {
                // 这里设置Viewer的配置，和new Viewer(container, options)中的options一致
                homeButton: true,
                timeline: true,
            });

            earth.sceneTree.root = {
                "children": [
                    {
                        "czmObject": {
                          "enabled": false,
                            "name": "默认离线影像",
                            "xbsjType": "Imagery",
                            "xbsjImageryProvider": {
                                "createTileMapServiceImageryProvider": {
                                    "url": XE.HTML.cesiumDir + 'Assets/Textures/NaturalEarthII',
                                    "fileExtension": 'jpg',
                                },
                                "type": "createTileMapServiceImageryProvider"
                            }
                        }
                    },
                ]
            };

            const scene = earth.czm.scene;

            Cesium.xbsjRotateEarth(scene, 26910000, 0.5);
            scene.xbsjEarthRotatingSpeed = Math.PI / 2;
            console.log(earth
            
            
            
            
            )
            setTimeout(()=>{
            earth.camera.flyTo([115.9173576 * Math.PI / 180, 36.8554955 * Math.PI / 180, 352.85])
               setTimeout(()=>{
                this.showInit = true
                   earth = earth && earth.destroy();
                  var childObj = document.getElementById("showmap")
                  childObj.style.animation='fadeOut 3s'  
                },3000)
                
             },2000)
            
        },
    clearQuery() {
      if (this.queryVectorObj) {
        this.map.remove(this.queryVectorObj)
        this.queryVectorObj = null
      }
    },
    setDrawPoint(val) {
      if (val) {
        this.getBoundsFeat(this.queryLayer)
        let activeToolItem = this.$refs.topTool.activeToolItem
        let image = pl
        switch (activeToolItem) {
          case 'node': {
            image = require('./image/tool-node.png')
            break
          }
          case 'monitoring': {
            image = require('./image/tool-monitoring.png')
            break
          }
          case 'valve': {
            image = require('./image/tool-valve.png')
            break
          }
          case 'userSta': {
            image = require('./image/tool-userSta.png')
            break
          }
          case 'station': {
            image = require('./image/tool-station.png')
            break
          }
          case 'warn': {
            image = require('./image/tool-warn.png')
            break
          }
          case 'camera': {
            image = require('./image/tool-camera.png')
            break
          }
        }
        if (!this.queryVectorObj) {
          this.queryVectorObj = new AMap.Marker({
            icon: new AMap.Icon({ image: image, imageOffset: new AMap.Pixel(0, 0) }),
            anchor: 'center',
            position: [0, 0]
          })
          this.map.add(this.queryVectorObj)
        } else {
          this.queryVectorObj.setIcon(
            new AMap.Icon({ image: image, imageOffset: new AMap.Pixel(0, 0) })
          )
        }
      } else {
        if (this.queryVectorObj) {
          this.map.remove(this.queryVectorObj)
          this.queryVectorObj = null
        }
      }
    },
    mouseHover(e) {
      let disX = e.pixel.x
      let disY = e.pixel.y
      if (this.$refs.drawTipmouse) {
        this.$refs.drawTipmouse.style.top = disY + 20 + 'px'
        this.$refs.drawTipmouse.style.left = disX + 270 + 'px'
      }
    },
    setStorInit() {
      this.$store.commit('gis/legendSelectChange', initFilter)
    },
    setlayerInit() {
      this.$store.commit('gis/legendSelectChange', initFilter)
      this.$eventBus.$emit('setFilter')
      // this.$store.commit('gis/legendSelectChange', initFilter) //默认选中图层
      // let filter =
      //   'node_type2 in (' + initFilter.toString() + ',' + constantDict.guanxian.dictCode + ')'
      // this.$refs.layerWms.init(filter, this.layerName)
    },
    settingsItem(val) {
      this.$refs[val].open()
    },
    
    getwarnIng(){
      let list = [];
      selectScadaLineWarn({}).then(res1=>{
        if(res1.data&&res1.data.length>0){
          let legendSelect = this.$store.getters.legendSelect
        
          if(!legendSelect.find(el=>el=='9000')){
            legendSelect.push(9000)
            this.$store.commit('gis/legendSelectChange', legendSelect)
            this.$eventBus.$emit('setFilter')
          }
        }
        selectScadaStationWarn({}).then(res2=>{
          res1.data.forEach(el=>{
            list.push(formatLocation(el, 'Point'))
          })
          res2.data.forEach(el=>{
            list.push(formatLocation(el, 'Point'))
          })
          this.pointHighlightAllGis(list)
        })
      })
    },
    pointHighlightAllGis(list){
      let that = this;
      this.removeHighligAllGis()
      list.forEach(el=>{
        let pos = new AMap.Marker({
          content:
            '<div class="red"> <div class="scatterPoint scatterPoint2"></div>' +
            '<div class="scatterPoint scatterPoint3" ></div></div>',
          offset: new AMap.Pixel(0, 0),
          position: el,
          extData: el
        })
        pos.on('click', function(e) {
          console.log(e)
          that.$refs.editFeature.editFeature(e.lnglat)
        })

        that.map.add(pos)
        that.LabelsLayerHighlightAll.push(pos)
      })

    },
    removeHighligAllGis(){
      if (this.LabelsLayerHighlightAll) {
        this.LabelsLayerHighlightAll.forEach(el=>{
          this.map.remove(el)
        })
        this.LabelsLayerHighlightAll =[]
      } 
    },
    async findSetting() {
      try {
        let { data } = await SetBase.findSetting()
        this.onConfirm(data, 1, true)
        this.loadStateSet = this.loadStateSet + 1
      } catch (error) {}
    },
    async onConfirm(val, type, need) {
      this.$store.commit('gis/isLegendSelectChange', [])
      if (!commonLayer[val]) {
        val = '40000'
      }
      this.layerName = commonLayer[val]

      if (this.loadStateSet > 2 && !need) {
        this.$eventBus.$emit('setFilter')
      }
      if (this.displaySettings === val) return
      if (!type) {
        try {
          await SetBase.setting({ value: val })
        } catch (error) {}
      }
      this.displayList = []
      this.pipeDisplayList = []
      this.displaySettings = val
      if (val == 'texture') {
        try {
          let res = await SetBase.getList({ pidDictCode: 1000240001 })
          let { data } = await SetBase.getList({ pidDictCode: 1000240002 })
           let data1 = await SetBase.getList({ pidDictCode: 1000240003 })
          res.data.forEach(el => {
            el.select = true
          })
          data.forEach(el => {
            el.select = true
          })
          data1.data.forEach(el => {
            el.select = true
          })
          data.push({
              "id": 1,
              "pidDictCode": "",
              "dictName": "默认",
              "dictCode": "0",
              "showName": "默认",
              "colour": "#ff0084",
              "sort": 1,
              "select": true
          })
          this.pipeDisplayList = [res.data, data,data1.data]
        } catch (error) {}
      } else {
        try {
          let res = await SetBase.getList({ pidDictCode: val })
          let retData = res.data
          retData.forEach(element => {
            element.select = true
          })
          retData.push({
              "id": 1,
              "pidDictCode": "",
              "dictName": "默认",
              "dictCode": "0",
              "showName": "默认",
              "colour": "#ff0084",
              "sort": 1,
              "select": true
          })
          this.displayList = retData
        } catch (error) {}
      }
    },
    initMap() {
      let that = this
      let map = new AMap.Map('amapStation', {
        ...that.mapConfig
      })
      that.map = map
      map.addControl(new AMap.Scale({
        position: {
            bottom: '12px',
            left: '120px'
        }
    }))

      that.map.on('complete', function() {
        that.$refs.zoom.init()
        //  that.getMark()
        that.drawEnd()
        // that.$refs.topTool.endToolDraw()
        that.loadStateSet = that.loadStateSet + 1
        that.map.setDefaultCursor('default')
        that.getwarnIng()
        that.timer = setInterval(() => {
          that.getwarnIng()
        }, 120000)
        that.getMarkStation(1)
      })

      that.map.on('mousemove', e => {
        let lnglat = e.lnglat
        if (that.isEdit) {
          that.mouseHover(e)
        }
        that.AdAnalysis(lnglat, e)

        if (that.drawType == 'rule') {
          let length = that.ruleObj.length
          if (length > 0) {
            let l = AMap.GeometryUtil.distanceOfLine([...that.ruleObj, lnglat])
            that.ruleM = l.toFixed(1)
            that.mouseHover(e)
          }
        }
      })
      that.map.on('moveend', e => {
         this.$nextTick(() => {
          if (that.zoom < that.maxZoom) {
          } else {          
            that.getBoundsFeat(that.queryLayer)          
          }
        })
      })
      that.map.on('click', e => {
        let that = this;
        
          that.$refs.editFeature.editFeature(e.lnglat)
        

        
       
      })
      that.map.on('rightclick', e => {
        if (!that.isEdit) {
          that.$refs.editFeature.changeLayer(e.lnglat)
        }
      })
      // that.map.on('mousedown', e => {
      //   if (that.drawState) {
      //     //绘制提示信息
      //     that.onClick(e)
      //   }
      // })

      //工具
      that.mouseTool = new AMap.MouseTool(that.map)
      // 管线设备工具条
      that.editTool = new AMap.MouseTool(that.map)
      // 安全设备工具条
      that.equipmentTool = new AMap.MouseTool(that.map)
      //   that.getMark() 默认显示
    },
    openQuery() {
      this.$refs.Childmain.openQuery() //打开综合查询页面
    },
    AdAnalysis(lnglat, e) {
      let that = this
      if (
        that.queryVectorObj &&
        that.layerName &&
        that.$store.getters.activeOptionItem == 'adsorption'
      ) {
        let distanceP = null
        let distanceL = null
        let feature = {}
        let maxlength = 10
        let point = {}
        let line = {}
        that.queryVectorGeo.forEach(item => {
          if (item.geometry.type == 'Point') {
            if (
              (item.properties.node_type2 == constantDict.jiedian.dictCode &&
                that.layerName == commonLayer['pressure']) ||
              that.layerName != commonLayer['pressure']
            ) {
              let path = formatLocation(item.properties.location_str, 'Point')
              let l = Math.round(AMap.GeometryUtil.distance(lnglat, path))
              if (!distanceP || distanceP > l) {
                distanceP = l
                item.l = l
                point = item
              }
            }
          } else {
            let path = formatLocation(item.properties.location_str, 'LineString')
            let l = Math.round(AMap.GeometryUtil.distanceToLine(lnglat, path))
            if (!distanceL || distanceL > l) {
              distanceL = l
              item.l = l
              line = item
            }
          }
        })
        if (point.l && line.l) {
          let d = point.l - line.l
          if (d > 5 && line) {
            feature = line
          } else {
            feature = point
          }
        } else {
          feature = point.geometry ? point : line
        }
        if (feature.properties) {
          let bounds = that.map.getBounds()
          let northEast = bounds.northEast //东北角 southWest
          let southWest = bounds.southWest
          let pixel = e.pixel
          let px = Math.sqrt(pixel.x * pixel.x + pixel.y * pixel.y)
          let pointObj = null
          if (feature.geometry.type == 'Point') {
            pointObj = formatLocation(feature.properties.location_str, 'Point')
          } else {
            let path = formatLocation(feature.properties.location_str, 'LineString')
            pointObj = AMap.GeometryUtil.closestOnLine(lnglat, path)
          }
          let m = Math.round(AMap.GeometryUtil.distance([southWest.lng, northEast.lat], pointObj))

          let m2 = Math.round(AMap.GeometryUtil.distance(lnglat, pointObj))
          //距离小于规定像素
          if (m2 * (px / m) < maxlength) {
            that.queryVectorObj.setPosition(pointObj)
            feature.pointObj = pointObj
            that.queryFeatrue = feature
          } else {
            that.queryVectorObj.setPosition(lnglat)
            that.queryFeatrue = null
          }
        } else {
          that.queryVectorObj.setPosition(lnglat)
          that.queryFeatrue = null
        }
      } else if (that.queryVectorObj && that.queryType) {
        that.queryVectorObj.setPosition(lnglat)
      }
    }
  }
}
</script>
<style lang="scss">
 @keyframes fadeOut {
    0% {
    opacity: 0;
    }
    100% {
      opacity: 1;
    }
 }
</style>
<style lang="scss" scoped>
.gis-main {
  position: relative;
}
::v-deep .el-dialog{
  .el-dialog__body{
    padding: 0px;
  }
}
::v-deep .amap-ranging-label {
  background: var(--background-color-skin);
  border: 1px solid var(--font-color-b1);
}

.layer-control {
  // float: left;
  background: var(--background-color-skin);
  height: calc(100vh - 150px);
  color: var(--font-color-f1);
  position: relative;
  

  .layer {
    width: 250px;
  }

  
}

.top-tool {
  position: absolute;
  z-index: 9;
  margin-left: 10px;
  margin-top: 11px;
  height: 10px;
}

.map-size {
  text-align: center;

  width: 100%;
  height: calc(100vh - 73px);
}

#amapStation {
  width: 100%;
  height: 100%;
}

.view {
  position: relative;
  flex: 1;
  color: var(--font-color-f1);
  // margin-left: 250px;
  width: auto;
}

// ::v-deep .amap-scalecontrol {
//   background: none !important;
//   left: 0.7rem !important;
//   bottom: 0.1rem !important;
//   transition: left 0.5s;
// }
::v-deep .amap-scalecontrol {
  background: none !important;
  left: 2px !important;
  bottom: 10px !important;
  transition: left 0.5s;
}

::v-deep .drawDel {
  color: #01d8ff;
  font-size: 18px;
}

::v-deep.deleteNode {
  content: url(./image/rule-remove.png);
}
.drawTipmouse {
  position: absolute;
  top: -100px;
  left: 0;
  z-index: 8;
  background: var(--background-color-skin);
  border-radius: 5px;
  color: var(--font-color-f1);
  padding: 5px 10px;
  white-space: nowrap;
}
</style>
